import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/ALDI-logo.svg'


const _hoisted_1 = { class: "page-title" }
const _hoisted_2 = { class: "data-loading-screen" }
const _hoisted_3 = { class: "data-loading-screen__content" }
const _hoisted_4 = {
  class: "data-loading-screen__title",
  "data-e2e": "start-page-screen-title"
}
const _hoisted_5 = { class: "list" }

import { onMounted } from 'vue';
import { CheckIcon, LoadingIcon } from '@/features/ui/icons';
import { retrySynchronisationsPlugin } from '@/features/retry-synchronisation';
import Dialog from '@/features/ui/components/Dialog.vue';
import { oauthServicePlugin } from '@/features/oauth';
import router from '@/features/core/router';
import { environmentConfigurationPlugin } from '@/features/core/environment-configuration';
import { useStart } from '../composables';
import { LoadingProcess } from '../types';
import { $t } from '@/i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'StartPage',
  setup(__props) {

const { loadData, loadingData } = useStart();
const { showSynchronisationFailPopup } = retrySynchronisationsPlugin.get();

onMounted(async () => {
  const url = await loadData();
  if (url) {
    await router.get().push(url);
  }
});

const isLoading = (loadingProcess: LoadingProcess) => {
  return loadingProcess === LoadingProcess.Loading;
};

const isLoaded = (loadingProcess: LoadingProcess) => {
  return loadingProcess === LoadingProcess.Loaded;
};

const handleLogout = async () => {
  await oauthServicePlugin.get().logout('empowerId', false, false);
};

const trainingModeActive = Boolean(
  environmentConfigurationPlugin.get().getConfig('trainingMode')?.isActive,
);

const title = trainingModeActive
  ? $t('pages.start-page.header-title-training-mode.text')
  : $t('pages.start-page.header-title.text');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_unref(title)), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "logo" }, [
          _createElementVNode("img", {
            alt: "ALDI logo",
            "data-e2e": "aldi-logo",
            src: _imports_0
          })
        ], -1)),
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_unref($t)('pages.start-page.screen-title.text')), 1),
        _createElementVNode("ul", _hoisted_5, [
          _createElementVNode("li", {
            class: _normalizeClass([{ loading: isLoading(_unref(loadingData).configuration) }, "list-item"]),
            "data-e2e": "list-item-configuration"
          }, [
            (isLoading(_unref(loadingData).configuration))
              ? (_openBlock(), _createBlock(_unref(LoadingIcon), {
                  key: 0,
                  class: "list-icon spinner"
                }))
              : _createCommentVNode("", true),
            (isLoaded(_unref(loadingData).configuration))
              ? (_openBlock(), _createBlock(_unref(CheckIcon), {
                  key: 1,
                  class: "list-icon",
                  width: "25"
                }))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_unref($t)('pages.start-page.configuration.text')), 1)
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass([{ loading: isLoading(_unref(loadingData).data) }, "list-item"]),
            "data-e2e": "list-item-master-data"
          }, [
            (isLoading(_unref(loadingData).data))
              ? (_openBlock(), _createBlock(_unref(LoadingIcon), {
                  key: 0,
                  class: "list-icon spinner"
                }))
              : _createCommentVNode("", true),
            (isLoaded(_unref(loadingData).data))
              ? (_openBlock(), _createBlock(_unref(CheckIcon), {
                  key: 1,
                  class: "list-icon",
                  width: "25"
                }))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_unref($t)('pages.start-page.master-data.text')), 1)
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass([{ loading: isLoading(_unref(loadingData).sync) }, "list-item"]),
            "data-e2e": "list-item-live-sync"
          }, [
            (isLoading(_unref(loadingData).sync))
              ? (_openBlock(), _createBlock(_unref(LoadingIcon), {
                  key: 0,
                  class: "list-icon spinner"
                }))
              : _createCommentVNode("", true),
            (isLoaded(_unref(loadingData).sync))
              ? (_openBlock(), _createBlock(_unref(CheckIcon), {
                  key: 1,
                  class: "list-icon",
                  width: "25"
                }))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_unref($t)('pages.start-page.live-sync.text')), 1)
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass([{ loading: isLoading(_unref(loadingData).order) }, "list-item"]),
            "data-e2e": "list-item-orders"
          }, [
            (isLoading(_unref(loadingData).order))
              ? (_openBlock(), _createBlock(_unref(LoadingIcon), {
                  key: 0,
                  class: "list-icon spinner"
                }))
              : _createCommentVNode("", true),
            (isLoaded(_unref(loadingData).order))
              ? (_openBlock(), _createBlock(_unref(CheckIcon), {
                  key: 1,
                  class: "list-icon",
                  width: "25"
                }))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_unref($t)('pages.start-page.orders.text')), 1)
          ], 2)
        ])
      ])
    ]),
    _createVNode(Dialog, {
      confirmText: _unref($t)('pages.start-page.app-updated-dialog.confirm.text'),
      contentText: _unref($t)('pages.start-page.app-updated-dialog.content.text'),
      showOnlyConfirm: true,
      titleText: _unref($t)('pages.start-page.app-updated-dialog.title.text'),
      visible: _unref(showSynchronisationFailPopup),
      onConfirmed: handleLogout
    }, null, 8, ["confirmText", "contentText", "titleText", "visible"])
  ], 64))
}
}

})